import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";
import noDataImage from "../../images/icons/incomplete.png";
import {
  getUserCreditDetails,
  createCheckoutSession,
  getBillingHistory,
  getPricingDetails,
} from "../services/api.services";
import "./AdminCredits.scss";

const AdminCredits = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [credit, setCredit] = useState(1);
  const [creditData, setCreditData] = useState(null);
  const [creditUnitCost, setCreditUnitCost] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [logData, setLogData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  let header = {
    Authorization: "Token " + state.token,
  };

  useEffect(() => {
    const fetchCreditDetails = async () => {
      try {
        const response = await getUserCreditDetails(header);
        if (response.data.isSuccess) {
          setCreditData(response.data.dataInfo);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching credit details:", error);
      } finally {
        setLoading(false);
      }
    };
    const fetchTransactionLog = async () => {
      try {
        const response = await getBillingHistory(header);
        if (response.data.isSuccess) {
          setLogData(response.data.dataInfo);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching credit details:", error);
      }
    };

    fetchCreditDetails();
    fetchTransactionLog();
  }, []);

  useEffect(() => {
    if (creditData && !creditData.is_subscription && credit > 0) {
      const fetchPricingDetails = async () => {
        try {
          const response = await getPricingDetails(header, credit);
          if (response.data.isSuccess) {
            setCreditUnitCost(response.data.pricing_data.unit_cost);
            setTotalAmount(response.data.pricing_data.total_amount);
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          console.error("Error fetching credit details:", error);
        }
      };

      fetchPricingDetails();
    }
  }, [credit, creditData]);

  const handleClick = async () => {
    if (credit <= 0) {
      toast.error("Credit must be greater than zero");
    } else {
      try {
        const productData = {
          // unit_amount: creditData.credit_cost,
          quantity: credit,
          credit_name: creditData.credit_name,
          currency: "gbp",
        };
        const response = await createCheckoutSession(header, productData);

        if (response.status === 200) {
          navigate("/learning/admin/checkout", {
            state: { clientSecret: response.data.clientSecret },
          });
        } else {
          toast.error("Failed to create checkout session");
        }
      } catch (error) {
        console.error("Error creating checkout session:", error);
        toast.error("Failed to create checkout session");
      }
    }
  };

  const handleCreditChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= 0) {
      setCredit(value);
      if (value !== 0) {
        setTotalAmount((value * creditUnitCost).toFixed(2));
      } else {
        setTotalAmount(0);
      }
    } else {
      setCredit(null);
      setTotalAmount(0);
    }
  };
  if (loading) {
    return <div className="admin-credits-loading"></div>;
  }
  return (
    <div className="admin-credits">
      <div className="admin-credits-title">
        {creditData && creditData.is_subscription
          ? "Billing History"
          : "Purchase Credits"}
      </div>
      <div className="admin-credits-body">
        {!creditData || !creditData.is_subscription ? (
          <div className="admin-credits-body-purchase">
            <div className="admin-credits-body-purchase-title">
              <div className="admin-credits-body-purchase-title-top">
                Buying Credits
              </div>
              <div className="admin-credits-body-purchase-title-bottom">
                Top-up your account
              </div>
            </div>
            <div className="admin-credits-body-purchase-body">
              <div className="admin-credits-body-purchase-body-left">
                <div className="admin-credits-body-purchase-body-left-ques">
                  How many credits would you like to buy?
                </div>
                <div className="admin-credits-body-purchase-body-left-input">
                  <div className="admin-credits-body-purchase-body-left-input-box">
                    <input
                      type="number"
                      id="credit"
                      value={credit !== null ? credit : ""}
                      onChange={handleCreditChange}
                    />
                  </div>
                  {creditUnitCost && (
                    <div className="admin-credits-body-purchase-body-left-input-text">
                      credits @ £ {creditUnitCost} each
                    </div>
                  )}
                </div>
                <div className="admin-credits-body-purchase-body-left-summary">
                  <div className="admin-credits-body-purchase-body-left-summary-key">
                    Total Amount :
                  </div>
                  <div className="admin-credits-body-purchase-body-left-summary-value">
                    {/* £ {creditData ? creditData.credit_cost * credit : 0} */}
                    £ {totalAmount ? totalAmount : 0}
                  </div>
                </div>
              </div>
              <div className="admin-credits-body-purchase-body-right">
                <div className="admin-credits-body-purchase-body-right-text">
                  Total Balance
                </div>
                <div className="admin-credits-body-purchase-body-right-value">
                  {creditData ? creditData.user_credit_balance : 0}
                </div>
              </div>
            </div>
            <div className="admin-credits-body-purchase-bottom">
              <button
                className="admin-credits-body-purchase-bottom-button"
                onClick={handleClick}
              >
                <span className="admin-credits-body-purchase-bottom-button-text">
                  Purchase Credit
                </span>
              </button>
              {/* <PurchaseCreditsPopup
              header={header}
              credit={credit}
              setCredit={setCredit}
            /> */}
            </div>
          </div>
        ) : null}
        <div className="admin-credits-body-history">
          <div className="admin-credits-body-history-table">
            <div className="admin-credits-body-history-table-title">
              Billing History
            </div>
            <div className="admin-credits-body-history-table-head">
              <div className="admin-credits-body-history-table-head-item">
                #
              </div>
              <div className="admin-credits-body-history-table-head-item">
                Transaction ID
              </div>
              <div className="admin-credits-body-history-table-head-item">
                Description
              </div>
              <div className="admin-credits-body-history-table-head-item">
                Date
              </div>
              <div className="admin-credits-body-history-table-head-item">
                Amount
              </div>
              <div className="admin-credits-body-history-table-head-item">
                Status
              </div>
            </div>
            <div className="admin-credits-body-history-table-body">
              {logData && logData.length > 0 ? (
                <>
                  {logData.map((data, index) => (
                    <div
                      className="admin-credits-body-history-table-body-row"
                      key={index}
                    >
                      <div className="admin-credits-body-history-table-body-row-item">
                        {index + 1}
                      </div>
                      <div className="admin-credits-body-history-table-body-row-item">
                        {data.transaction_id}
                      </div>
                      <div className="admin-credits-body-history-table-body-row-item">
                        {data.transaction_status
                          ? `Purchased ${data.credits_purchased} credit points`
                          : "Failed to purchase credit points"}
                      </div>
                      <div className="admin-credits-body-history-table-body-row-item">
                        {data.transaction_date}
                      </div>
                      <div className="admin-credits-body-history-table-body-row-item">
                        £ {data.transaction_amount}
                      </div>

                      <div className="admin-credits-body-history-table-body-row-item">
                        {data.transaction_status ? "success" : "failed"}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="admin-credits-body-history-table-body-empty">
                  <img
                    src={noDataImage}
                    alt="No Data Available"
                    className="admin-credits-body-history-table-body-empty-image"
                  />
                  <p className="admin-credits-body-history-table-body-empty-message">
                    No data available to display.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCredits;

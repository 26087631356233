import React, { useEffect, useState, useContext } from 'react';
import './ScormPlayer.scss';
import '../../../theme/_buttons.scss';
import SCORM_API from '../services/scormapi';
import ScormAPIWrapper from '../scorm/ScormAPIWrapper';
import { json } from 'react-router-dom';
import { getScormData, getImsManifestFile } from '../services/api.services';
import { AuthContext } from '../../../App';
import toast from "react-hot-toast";
import axios from "axios";
import xmlJs from 'xml-js';
import CookieSetup from '../../../common/services/CookieSetup';



const ScormPlayer = ({ openScormCourse, scormURL, userCourseId, isScorm, scormProgressId, setScormProgressId }) => {
    const { state, dispatch } = useContext(AuthContext);
    const [rootFileURL, setRootFileURL] = useState('')
    const [commitInitiated, setCommitInitiated] = useState(false)
    window.API = SCORM_API;
    const {
      initialize,
      terminate,
      getValue,
      setValue,
      commit,
      initialized,
      finishCalled
    } = ScormAPIWrapper();

    useEffect(()=>{
        getImsManifestValue();
        initializeScrom();
        getValueScormAPI();
    }, [initialized, finishCalled, openScormCourse])

    const getImsManifestValue = async() => {
        let cookieHeader = {
            Cookie: CookieSetup.getCookie('cloudfront-cookie')
        }
        await getImsManifestFile(scormURL, cookieHeader)
        .then(function (response) {
            const jsonData = JSON.parse(xmlJs.xml2json(response.data, { compact: true, spaces: 4 }));
            const baseURL = scormURL.substring(0, scormURL.lastIndexOf("/") + 1);
            var manifestResourse = jsonData.manifest.resources.resource
            if(Array.isArray(jsonData.manifest.resources.resource)){
                setRootFileURL(baseURL.concat(manifestResourse[0]._attributes.href))
            }else{
                setRootFileURL(baseURL.concat(manifestResourse._attributes.href))
            }
            
        })
        .catch(function (error) {
            console.log(error);
        })
    }
    const initializeScrom = () => {
        const init = initialize();
        if(init){
            setValueScormAPI();
        }
    }
    const setValueScormAPI = () => {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'))
        var userData = {
            "cmi.core.student_id": userDetails.id,
            "cmi.core.student_name": userDetails.username,
            "user_course": userCourseId,
            "Token": state.token,
        }
        if(scormProgressId){
            userData["scormProgressId"] = scormProgressId
        }
        
        Object.entries(userData).map(([key, value]) => {
            setValue(key, value)
        })
        if(isScorm && scormProgressId !== null){
            fetchScormData()
        }
        // Commited to post the data of a new course. 
        if(!commitInitiated && scormProgressId === null){
            commitScormAPI()
        }
        terminate()
    }
    const getValueScormAPI = () => {
        setScormProgressId(getValue('scormProgressId'));
    }
    const commitScormAPI = () =>{
        let commited = commit()
        setCommitInitiated(commited);
    }
    let header = {
        Authorization: "Token " + state.token,
      };
    const fetchScormData = async() => {
        if(scormProgressId){
            await getScormData(header, scormProgressId)
            .then((resp) => {
                if(resp.status == 200){
                    let responseData = resp.data.data
                    if(responseData){
                        Object.entries(responseData).forEach(([key, value]) => {
                            setValue(key, value)
                        })
                    }
                }else{
                    toast.error("Unable to find your progress of this course!")
                }
            })
        }
    }
    // const handleScormPopupClose = () => {
    //     commit()
    //     setOpenScormCourse(false)
    // }
   
  return (
    <div className='app-scorm_player'>
        <iframe
            src={rootFileURL}
            width="100%"
            height="100%"
        ></iframe>
    </div>
  )
}

export default ScormPlayer

import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const getAdminCourseData = async (
  header,
  page = 1,
  pageSize = 70,
  searchKey = null
) => {
  try {
    let url;
    if (searchKey) {
      url = `api/learning-app/admin/courses/?page=${1}&search=${searchKey}`;
    } else {
      url = `api/learning-app/admin/courses/?page=${page}&page_size=${pageSize}`;
    }
    const res = await instance.get(url, {
      headers: header,
    });
    // const res = await instance.get("api/learning-app/admin/course/", { headers: header });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const createAdminCourse = async (header, courseData) => {
  try {
    const res = await instance.post(
      "api/learning-app/admin/course/",
      courseData,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const updateAdminCourse = async (
  header,
  courseId,
  updatedCourseData
) => {
  try {
    const res = await instance.put(
      `api/learning-app/admin/course/${courseId}/`,
      updatedCourseData,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const deleteAdminCourse = async (header, courseId) => {
  try {
    const res = await instance.delete(
      `api/learning-app/admin/course/${courseId}/`,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getAdminModuleData = async (header) => {
  try {
    const res = await instance.get("api/learning-app/admin/module/", {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const createAdminModule = async (header, moduleData) => {
  try {
    const res = await instance.post(
      "api/learning-app/admin/module/",
      moduleData,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const updateAdminModule = async (
  header,
  moduleId,
  updatedModuleData
) => {
  try {
    const res = await instance.put(
      `api/learning-app/admin/module/${moduleId}/`,
      updatedModuleData,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const deleteAdminModule = async (header, moduleId) => {
  try {
    const res = await instance.delete(
      `api/learning-app/admin/module/${moduleId}/`,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getAdminChapterData = async (header) => {
  try {
    const res = await instance.get("api/learning-app/admin/chapter/", {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const createAdminChapter = async (header, chapterData) => {
  try {
    const res = await instance.post(
      "api/learning-app/admin/chapter/",
      chapterData,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const updateAdminChapter = async (
  header,
  chapterId,
  updatedChapterData
) => {
  try {
    const res = await instance.put(
      `api/learning-app/admin/chapter/${chapterId}/`,
      updatedChapterData,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const deleteAdminChapter = async (header, chapterId) => {
  try {
    const res = await instance.delete(
      `api/learning-app/admin/chapter/${chapterId}/`,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getAllCourses = async (header) => {
  try {
    const res = await instance.get("api/learning-app/admin/all-courses/", {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getAllModules = async (header) => {
  try {
    const res = await instance.get("api/learning-app/admin/all-modules/", {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const createTraineeData = async (header, traineeData) => {
  try {
    const res = await instance.post(
      "api/learning-app/admin/trainee/",
      traineeData,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getTraineeData = async (
  header,
  page = 1,
  pageSize = 5,
  searchKey = null
) => {
  try {
    let url;
    if (searchKey) {
      url = `api/learning-app/admin/trainee/?search=${searchKey}`;
    } else {
      url = `api/learning-app/admin/trainee/?page=${page}&page_size=${pageSize}`;
    }
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};

export const setTraineeData = async (header, idArray, update_to_status) => {
  try {
    const res = await instance.put(
      "api/learning-app/admin/trainee/update-trainee-status/",
      {
        user_ids: idArray,
        update_to_status: update_to_status,
      },
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const deleteTraineeData = async (header, idArray) => {
  try {
    // Convert the array of IDs to a comma-separated string
    const idString = idArray.join(",");
    const res = await instance.delete(
      `api/learning-app/admin/trainee/update-trainee-status/?user_ids=${idString}`,
      {
        headers: header,
      }
    );

    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const updateCreditBalance = async (
  header,
  value,
  addCredit,
  sessionId
) => {
  try {
    const res = await instance.put(
      "api/user-app/admin/credit/",
      { value, add_credit: addCredit, session_id: sessionId },
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getUserCreditDetails = async (header) => {
  try {
    const res = await instance.get("api/user-app/admin/credit/", {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const createCheckoutSession = async (header, productData) => {
  try {
    const res = await instance.post(
      "api/payments/initiate_checkout_session/",
      productData,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getPricingDetails = async (header, credit) => {
  try {
    const res = await instance.get("api/payments/get_pricing_details/", {
      headers: header,
      params: {
        credit_count: credit,
      },
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getSessionInfo = async (header, sessionId) => {
  try {
    const res = await instance.get(
      `api/payments/get_session_status/?session_id=${sessionId}`,
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getBillingHistory = async (header) => {
  try {
    const res = await instance.get("api/payments/get_billing_history/", {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const enrollCourse = async (header, enrollData) => {
  try {
    const res = await instance.post(
      "api/learning-app/admin/enroll-course/",
      enrollData,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getStripeSession = async (header, planId) => {
  try {
    const res = await instance.post(
      "api/payments/subscription/get_stripe_client_secret_key/",
      { plan_id: planId },
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const createStripeSubscription = async (header) => {
  try {
    const res = await instance.post(
      "api/payments/subscription/",
      {},
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const setPricingPlan = async (header, planId) => {
  try {
    const res = await instance.post(
      "api/user-app/admin/set-pricing-plan/",
      { plan_id: planId },
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getPricingPlanDetails = async (header) => {
  try {
    const res = await instance.get("api/user-app/admin/pricing-plan/", {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getSetupSessionStatus = async (header, sessionId) => {
  try {
    const res = await instance.get(
      `api/payments/get_setup_session_status/?session_id=${sessionId}`,
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const downloadExcelTemplate = async (header) => {
  try {
    const res = await instance.get(
      "api/learning-app/admin/trainee/get-trainee-template/",
      {
        headers: header,
        responseType: "blob",
      }
    );
    return res;
  } catch (err) {
    console.log("Error downloading template:", err);
    return { status: "error", message: err };
  }
};

export const uploadExcelTemplate = async (file, header) => {
  const formData = new FormData();
  formData.append("excel", file);

  try {
    const res = await instance.post(
      "api/learning-app/admin/trainee/upload-trainee/",
      formData,
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getUploadTaskStatus = async (header, taskId) => {
  try {
    const res = await instance.get(
      `api/learning-app/admin/trainee/get-background-task/?task_id=${taskId}`,
      { headers: header }
    );
    return res.data;
  } catch (err) {
    console.log("Error fetching task status:", err);
    return { status: "error", message: err };
  }
};

export const getDynamicContainerData = async (header) => {
  try {
    const res = await instance.get(
      "api/learning-app/admin/trainee/get-dynamic-dashboard-data/",
      { headers: header }
    );
    return res.data;
  } catch (err) {
    console.log("Error fetching get_dynamic_dashboard_data:", err);
    return { status: "error", message: err };
  }
};

export const getAdminDashboardData = async (header) => {
  try {
    const res = await instance.get("api/learning-app/admin/dashboard/", {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getTraineeDetailsApi = async (header, trainee_id) => {
  try {
    const res = await instance.get(
      `api/learning-app/admin/trainee/${trainee_id}/`,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getTraineeCoursesDetailsPaginateApi = async (
  header,
  pageNo,
  recordsPerPage,
  trainee_id
) => {
  try {
    const res = await instance.get(
      `api/learning-app/user/user-course/?page=${pageNo}&page_size=${recordsPerPage}&trainee_id=${trainee_id}`,
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const updateTraineeDetailsApi = async (
  header,
  trainee_id,
  traineeDetails
) => {
  try {
    const res = await instance.patch(
      `api/learning-app/admin/trainee/${trainee_id}/`,
      traineeDetails,
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const updateTraineeAllCertificateApi = async (header, trainee_id) => {
  try {
    const res = await instance.get(
      `api/learning-app/admin/trainee/${trainee_id}/get-all-certificates/`,
      { headers: header, responseType: "blob" }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const updateTraineeCertificateApi = async (
  header,
  format,
  user_course_id
) => {
  try {
    const res = await instance.get(
      `api/learning-app/user/user-course/${user_course_id}/download-course-certificate/?content_type=${format}`,
      { headers: header, responseType: "blob" }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const unenrollCourse = async (header, userCourseIds, traineeId) => {
  try {
    const res = await instance.post(
      "api/learning-app/user/user-course/unenroll-courses/",
      {
        user_course_ids: userCourseIds,
        trainee_id: traineeId,
      },
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getRequests = async (
  header,
  type = null,
  page = 1,
  pageSize = 10
) => {
  try {
    const url = type
      ? `api/learning-app/admin/request/?type=${type}&page=${page}&page_size=${pageSize}`
      : `api/admin/request/?page=${page}&page_size=${pageSize}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const searchRequests = async (header, type, searchKey) => {
  try {
    const url = `api/learning-app/admin/request/?type=${type}&search=${searchKey}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const createRequest = async (header, requestData) => {
  try {
    const res = await instance.post(
      "api/learning-app/admin/request/",
      requestData,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const removeRequest = async (header, requestIds) => {
  try {
    const res = await instance.post(
      "api/learning-app/admin/request/delete-requests/",
      { request_ids: requestIds },
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getCourseBundles = async (
  header,
  page = 1,
  pageSize = 5,
  searchKey = null
) => {
  try {
    let url = `api/learning-app/admin/course-bundles/?page=${page}&page_size=${pageSize}`;
    if (searchKey) {
      url += `&search=${searchKey}`;
    }
    const res = await instance.get(url, {
      headers: header,
    });
    // const res = await instance.get(
    //   "api/learning-app/admin/course-bundles/",
    //   { headers: header}
    // );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const enrollCourseBundle = async (header, enrollData) => {
  try {
    const res = await instance.post(
      "api/learning-app/admin/enroll-course/",
      enrollData,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};


const CookieSetup = {
    setCookie: function(cookies){
        Object.entries(cookies).map(([key, value]) => {
            document.cookie = `${key}=${value}; Secure;`
            // console.log('cookie is set')
          })
    },
    getCookie: function(name){
        if(name === 'cloudfront-cookie'){
            var cookies = document.cookie.match(new RegExp(`(^|)CloudFront-Policy=([^;]+)`))[0]+';'+document.cookie.match(new RegExp(`(^|)CloudFront-Signature=([^;]+)`))[0]+';'+document.cookie.match(new RegExp(`(^|)CloudFront-Key-Pair-Id=([^;]+)`))[0]
            return cookies;
        }
        else{
            return '';
        }

    }
}

export default CookieSetup;

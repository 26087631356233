import React, { useState, useEffect, useContext } from "react";
import "./ChapterQuiz.scss";
import { FaArrowRight } from "react-icons/fa";
import toast from "react-hot-toast";
import { AuthContext } from "../../../App";
import {
  getMockTestQuestionsData,
  createMockTestAttempt,
} from "../services/api.services";
import ChapterQuizResult from "./ChapterQuizResult";

const ChapterQuiz = ({
  header,
  chapterId,
  quizData,
  lastAttemptData,
  onBack,
  handleNextChapter,
  progress,
  setSelectedChapter,
  isLastChapter
}) => {
  const [questionsData, setQuestionsData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showFinishButton, setShowFinishButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [quizFinished, setQuizFinished] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [attemptData, setAttemptData] = useState(lastAttemptData);
  const { state } = useContext(AuthContext);
  useEffect(() => {
    fetchQuestionsData();
  }, [quizData.id, state.token]);

  const fetchQuestionsData = async () => {
    try {
      const header = {
        Authorization: "Token " + state.token,
      };

      const response = await getMockTestQuestionsData(header, quizData.id);

      if (response.data.isSuccess) {
        setQuestionsData(response.data.dataInfo);
        if(response.data.dataInfo.length == 1){
          setShowFinishButton(true)
        }
      } else {
        toast.error("Failed to fetch questions data.");
      }
    } catch (error) {
      toast.error("An error occurred while fetching questions data:", error);
    }
  };

  const handleRadioButtonClick = (questionId, answerId) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [currentQuestion + 1]: { question: questionId, answer: answerId },
    });
    console.log('-------------------------------->',selectedAnswers)
  };

  const handleNextButtonClick = () => {
    const nextQuestion = currentQuestion + 1;

    if (
      !selectedAnswers.hasOwnProperty(currentQuestion + 1) ||
      !selectedAnswers[currentQuestion + 1].answer
    ) {
      toast.error("Please select an answer before moving to the next question.");
      return;
    }

    setCurrentQuestion(nextQuestion);

    if (nextQuestion === quizData["Number of Questions"] - 1) {
      setShowFinishButton(true);
    }

    const newProgressPercentage =
      ((nextQuestion + 1) / quizData["Number of Questions"]) * 100;
    setProgressPercentage(newProgressPercentage);
  };

  const handleFinishButtonClick = () => {
    if (
      !selectedAnswers.hasOwnProperty(currentQuestion + 1) ||
      !selectedAnswers[currentQuestion + 1].answer
    ) {
      toast.error("Please select an answer before finishing the quiz.");
      return;
    }

    const toastId = toast.loading("Submitting quiz...");

    let header = {
      Authorization: "Token " + state.token,
      "Content-Type": "application/json",
    };

    const formattedData = Object.keys(selectedAnswers).reduce((acc, key) => {
      acc[key] = {
        question: selectedAnswers[key].question,
        answer: selectedAnswers[key].answer,
      };
      return acc;
    }, {});

    setIsLoading(true);

    createMockTestAttempt(header, quizData.id, formattedData)
        // console.log('data--------->',selectedAnswers)
      .then((resp) => {
        toast.dismiss(toastId);
        if (resp.data.isSuccess) {
          setIsLoading(false);
          toast.success("Quiz Submitted!");
          setResultData(resp.data);
          setQuizFinished(true);
        } else {
          setIsLoading(false);
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        toast.dismiss(toastId);
        setIsLoading(false);
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  const handleRetakeQuiz = () => {
    setQuestionsData([]);
    setCurrentQuestion(0);
    setSelectedAnswers({});
    setShowFinishButton(false);
    setIsLoading(false);
    setProgressPercentage(0);
    setQuizFinished(false);
    setResultData(null);
    fetchQuestionsData();
    setAttemptData(null);
  };
  const handleNextClick = () => {
    handleNextChapter();
  };
  const questionsRemaining = quizData["Number of Questions"] - currentQuestion;
  return (
    <>
      {attemptData !== null ? (
        <ChapterQuizResult
          title={"Last Attempt Result"}
          header={header}
          chapterId={chapterId}
          dataInfo={attemptData}
          onBack={setSelectedChapter}
          onRetakeTest={handleRetakeQuiz}
          onNextChapterClick={handleNextClick}
          progress={progress}
          isLastChapter={isLastChapter}
        />
      ) : quizFinished ? (
        <ChapterQuizResult
          title={"Quiz Completed"}
          header={header}
          chapterId={chapterId}
          dataInfo={resultData?.dataInfo} // Use resultData's dataInfo as fallback
          onBack={setSelectedChapter}
          onRetakeTest={handleRetakeQuiz}
          onNextChapterClick={handleNextClick}
          progress={progress}
          isLastChapter={isLastChapter}
        />
      ) : (
        <div className="chapter_quiz">
          <div className="chapter_quiz-title">
            <div className="chapter_quiz-title-text">{quizData.name}</div>
            <div className="chapter_quiz-title-details">
              <div className="chapter_quiz-title-details-left">
                <span style={{ fontWeight: "bold" }}>Questions Remaining</span>:{" "}
                <span>
                  {questionsRemaining} / {quizData["Number of Questions"]}
                </span>
              </div>
              {/* <div className="chapter_quiz-title-details-right">
                <span style={{ fontWeight: "bold" }}>Questions Skipped</span>:{" "}
                <span>0</span>
              </div> */}
            </div>
          </div>
          <div className="chapter_quiz-body">
            {currentQuestion < questionsData.length && (
              <div className="chapter_quiz-body-top">
                <div className="chapter_quiz-body-top-module_name">
                  {questionsData[currentQuestion].module_name}
                </div>
                <div className="chapter_quiz-body-top-question">
                  <span style={{ marginInlineEnd: "5px" }}>{`Q${
                    currentQuestion + 1
                  }:`}</span>
                  <span>{questionsData[currentQuestion].text}</span>
                </div>
                <div className="chapter_quiz-body-top-answer">
                  {questionsData[currentQuestion].answers.map((answer) => (
                    <div
                      key={answer.id}
                      className="chapter_quiz-body-top-answer-option"
                    >
                      <input
                        type="radio"
                        id={answer.id}
                        name={`question_${questionsData[currentQuestion].id}`}
                        value={answer.id}
                        checked={
                          selectedAnswers[currentQuestion + 1]?.answer ===
                          answer.id
                        }
                        onChange={() =>
                          handleRadioButtonClick(
                            questionsData[currentQuestion].id,
                            answer.id
                          )
                        }
                      />
                      <label htmlFor={answer.id}>{answer.text}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="chapter_quiz-body-bottom">
              <div className="chapter_quiz-body-bottom-progress">
                <div className="chapter_quiz-body-bottom-progress-container">
                  <div
                    className="chapter_quiz-body-bottom-progress-container-filler"
                    style={{ width: `${progressPercentage}%` }}
                  ></div>
                </div>
              </div>
              <div className="chapter_quiz-body-bottom-button">
                {/* <button style={{ backgroundColor: "#656e80", color: "white" , borderColor:'#656e80'}}>
                  Skip
                </button> */}
                {showFinishButton ? (
                  <button className="finish-button" onClick={handleFinishButtonClick}>Finish</button>
                ) : (
                  <button className="primary-button" onClick={handleNextButtonClick}>
                    Next <FaArrowRight />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChapterQuiz;

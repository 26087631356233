import React from "react";
import "./ChapterQuizResult.scss";
import { updateChapterProgress } from "../services/api.services";
import ViewAnswerPopup from "./ViewAnswerPopup";
import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import toast from "react-hot-toast";

const ChapterQuizResult = ({
  title,
  header,
  chapterId,
  dataInfo,
  onBack,
  onRetakeTest,
  onNextChapterClick,
  progress,
  isLastChapter
}) => {
  const attemptResult = dataInfo.attempt_result;
  const handleNextClick = async () => {
    if (progress === 100) {
      onNextChapterClick();
    } else {
      if (dataInfo.is_passed) {
        onNextChapterClick();
        setChapterProgress(); // Invoke setChapterProgress if the test is passed
      } else {
        toast.error("Pass the test to view next chapter");
      }
    }
  };
  const setChapterProgress = async () => {
    const payload = { progress: 100, is_complete: "True" };
    await updateChapterProgress(header, chapterId, payload)
      .then((resp) => {
        if (resp.data.isSuccess) {
          toast.success("Chapter Completed!");
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  const handleRetakeTest = () => {
    onRetakeTest();
  };

  return (
    <div className="chapter_quiz_result">
      <div className="chapter_quiz_result-title">
        <div className="chapter_quiz_result-title-text">{title}</div>
        <div className="chapter_quiz_result-title-details">
          <div className="chapter_quiz_result-title-details-left">
            <span style={{ fontWeight: "bold" }}>Marks Obtained</span>:{" "}
            <span>{dataInfo.marks} points</span>
          </div>
          <div className="chapter_quiz_result-title-details-right">
            <span style={{ fontWeight: "bold" }}>Result</span>:{" "}
            <span>{dataInfo.is_passed ? "Passed" : "Failed"}</span>
          </div>
        </div>
      </div>
      <div className="chapter_quiz_result-body">
        <table className="chapter_quiz_result-body-table">
          <div className="chapter_quiz_result-body-table-head">
            <div className="chapter_quiz_result-body-table-head-item">
              Question No.
            </div>
            <div className="chapter_quiz_result-body-table-head-item">
              Answer Status
            </div>
            <div className="chapter_quiz_result-body-table-head-item">
              Correct Answer
            </div>
          </div>
          <div className="chapter_quiz_result-body-table-body">
            {attemptResult.map((data, index) => (
              <div
                className="chapter_quiz_result-body-table-body-row"
                key={data.id}
              >
                <div className="chapter_quiz_result-body-table-body-row-item">
                  {index + 1}
                </div>
                <div className="chapter_quiz_result-body-table-body-row-item">
                  {data.answer_status ? (
                    <FaRegCheckCircle style={{ color: "green" }} />
                  ) : (
                    <FaRegTimesCircle style={{ color: "red" }} />
                  )}
                </div>
                <div className="chapter_quiz_result-body-table-body-row-item">
                    <ViewAnswerPopup
                      question_number={index + 1}
                      module_name={data.module_name}
                      question_text={data.text}
                      selected_answer={data.selected_answer}
                      correct_answer={data.correct_answer}
                      answers={data.answers}
                    />
                </div>
              </div>
            ))}
          </div>
          <div className="chapter_quiz_result-body-table-bottom">
            <div className="chapter_quiz_result-body-table-bottom-back">
              <button className="back-button" onClick={()=>{onBack(null)}}>Go Back</button>
            </div>
            <div className="chapter_quiz_result-body-table-bottom-buttons">
              <button className="secondary-button" onClick={handleRetakeTest}>Retake Test</button>
              <button className="primary-button" onClick={handleNextClick}>{isLastChapter? "Finish":"Next Chapter"}</button>
            </div>

           
            
          </div>
        </table>
      </div>
    </div>
  );
};

export default ChapterQuizResult;
